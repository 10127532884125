<script setup lang="ts">
import { HTMLProps } from "../types-utils";

const props = withDefaults(
	defineProps<
		{
			expanded?: boolean;
			display?: "block" | "flex";
		} & HTMLProps
	>(),
	{ expanded: false, display: "block" }
);

const display = computed(() => props.display);
</script>

<template>
	<div v-if="$slots.title">
		<slot name="title" />
	</div>
	<div v-if="!expanded" class="collapse">
		<slot name="collapsed" />
	</div>
	<div v-else class="collapse">
		<slot name="expanded" />
	</div>
</template>
<style lang="scss" scoped>
.collapse {
	display: v-bind(display);
}
</style>
